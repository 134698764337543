import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import { AmrTransaction } from '../../../../../types/amr-pipeline/models/AmrTransaction';
import { AppState } from '../../../../../types/state/AppState';
import { DealsState } from '../../../../../types/state/DealsState';
import { SectionContent } from '../../../../amrPipeline/common/section/SectionContent';
import { Preloader } from '../../../../common';
import { useHistory, useParams } from 'react-router';
import { DealsRouteParams } from '../../../Deals';
import { SelectField } from '../../../../common/form-fields/SelectField';
import { DateField } from '../../../../common/form-fields/DateField';
import { NumberField } from '../../../../common/form-fields/NumberField';
import { DealsSaveHeader } from '../../../DealsSaveHeader';
import { constants, routes } from '../../../../../constants';
import { Grid } from '../../../../grid';
import { useEffect, useState } from 'react';
import { has, isEqual, isNil } from 'lodash';
import { useAppDispatch } from '../../../../../effects/useAppDispatch';
import { dealsActions } from '../../../../../actions/deals.actions';
import { gridUtils } from '../../../../../utils';
import RouteLeavingGuard from '../../../../routing/RouteLeavingGuard';
import { EditDocumentsSection } from '../../documents/edit/EditDocumentsSection';
import * as Yup from 'yup';
import { AmrInfoSaveForm } from '../../../../../types/deals/AmrInfoSaveForm';
import { amrStatusesSelectOptions } from '../../../../../types/amr-pipeline/enums/AmrStatus';
import { useAmrDocuments } from '../../../../../effects/useAmrDocuments';
import { amrPipelineService } from '../../../../../services/amr-pipeline.service';
import { gridActions } from '../../../../../actions';
import { DocumentColumnDate } from '../../documents/DocumentsList';

export const validationSchema = Yup.object().shape({
    electionNoticeDate: Yup.date().required(),
    informationNoticeDate: Yup.date().required(),
});

export const AmrInfoEdit = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();

    const { selectedDeal, isUpdating } = useSelector<AppState, DealsState>(state => state.deals);
    const dataItems = useSelector((state: AppState) => state.grid.dataItems);

    const [isUploading, setIsUploading] = useState(false);

    const { transactionReferenceName } = useParams<DealsRouteParams>();

    const selectedTransaction = selectedDeal?.transactions?.find(
        t => t.referenceName === transactionReferenceName,
    ) as AmrTransaction;

    const { amrDocuments } = useAmrDocuments({
        dealReferenceName: selectedDeal?.referenceName,
        transactionReferenceName: selectedTransaction?.referenceName,
    });

    const hasGridChanges = () => !isEqual(selectedTransaction.classes, gridUtils.sanitizeGridItems(dataItems));

    useEffect(() => {
        if (isNil(selectedDeal)) {
            return;
        }

        dispatch(dealsActions.amrInfoClassesEdit(selectedTransaction.classes));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isNil(selectedDeal)]);

    useEffect(() => {
        return () => {
            dispatch(gridActions.reset());
        };
    }, [dispatch]);

    if (!selectedDeal || isUpdating) {
        return <Preloader inProgress />;
    }

    const handleSave = (value: AmrInfoSaveForm) => {
        dispatch(dealsActions.amrInfoSave(selectedDeal.referenceName, selectedTransaction.referenceName, value));
    };

    const initialValues: AmrInfoSaveForm = {
        amrStatus: selectedTransaction.amrStatus,
        electionNoticeDate: selectedTransaction.electionNotice.noticeDate,
        informationNoticeDate: selectedTransaction.informationNoticeDate,
        electionToInformationBusinessDays: selectedDeal.electionToInformationBusinessDays,
        informationToPricingBusinessDays: selectedDeal.informationToPricingBusinessDays,
        pricingToSettlementBusinessDays: selectedDeal.pricingToSettlementBusinessDays,
        amrDocuments: amrDocuments ?? [],
        classes: selectedTransaction.classes,
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={handleSave}
        >
            {({ dirty, submitForm, setFieldValue, values }) => (
                <div className="tabs-content container-flex">
                    <RouteLeavingGuard
                        navigate={(pathname: string) => history.push(pathname)}
                        shouldBlockNavigation={() => (hasGridChanges() || dirty) && !isUpdating}
                    />
                    <DealsSaveHeader
                        title="Edit AMR"
                        subTitle={selectedDeal!.legalName}
                        onSaveClick={submitForm}
                        isUpdating={isUploading}
                        hasChanges={dirty || hasGridChanges()}
                        onCancelClick={() =>
                            history.push(routes.dealAmrInfoUrl(selectedDeal!.referenceName, transactionReferenceName))
                        }
                    />
                    <div className="tabs-data tabs-data-padding tabs-data-amr-info-edit">
                        <SectionContent title="Deal Info" className="data-item-general-cols">
                            <div className="data-item-row-col">
                                <SelectField
                                    name="amrStatus"
                                    label="AMR Status"
                                    placeholder="Select AMR Status"
                                    optionKeyProp="key"
                                    optionLabelProp="title"
                                    values={amrStatusesSelectOptions}
                                />
                                <DateField
                                    dateFormat={constants.dateFormat}
                                    required
                                    label="Election Notice"
                                    name="electionNoticeDate"
                                    emptyIfNull
                                    withCustomView
                                />
                                <DateField
                                    dateFormat={constants.dateFormat}
                                    required
                                    label="Information Notice"
                                    name="informationNoticeDate"
                                    emptyIfNull
                                    withCustomView
                                />
                            </div>
                            <div className="data-item-row-col">
                                <NumberField
                                    label="Election — Information, b. days"
                                    name="electionToInformationBusinessDays"
                                    capitalize={false}
                                />
                                <NumberField
                                    label="Information — Pricing, b. days"
                                    name="informationToPricingBusinessDays"
                                    capitalize={false}
                                />
                                <NumberField
                                    label="Pricing — Settlement, b. days"
                                    name="pricingToSettlementBusinessDays"
                                    capitalize={false}
                                />
                            </div>
                        </SectionContent>
                        <SectionContent title="AMR Classes">
                            <Grid
                                dataUploadDisabled
                                deleteRowVisible={false}
                                addRowVisible={false}
                                moveRowVisible={false}
                            />
                        </SectionContent>
                        <div className="tabs-data-documents-edit">
                            <EditDocumentsSection
                                documentKey="amrDocuments"
                                title="AMR Documents"
                                dealReferenceName={selectedDeal.referenceName}
                                items={values.amrDocuments}
                                withPublicColumn
                                documentColumnDate={DocumentColumnDate.uploadTime}
                                setUploadProgress={setIsUploading}
                                downloadAllFetch={amrPipelineService.getTransactionDocuments}
                                downloadAllArgs={[selectedDeal.referenceName, selectedTransaction.referenceName]}
                                fileNameForSave={`${selectedTransaction.dealLegalName} Documents.zip`}
                                onUpload={files =>
                                    setFieldValue(
                                        'amrDocuments',
                                        files.map(f => ({
                                            ...f,
                                            isPublic: has(f, 'isPublic') ? f.isPublic : true,
                                            dealReferenceName: selectedDeal.referenceName,
                                            transactionReferenceName: selectedTransaction.referenceName,
                                        })),
                                    )
                                }
                            />
                        </div>
                    </div>
                </div>
            )}
        </Formik>
    );
};
