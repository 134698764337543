import { useFormikContext } from "formik";
import classNames from "classnames";
import { SORTING_TYPE, constants } from "../../../../../constants";
import IconSVG from "../../../../../styles/svg-icons";
import { dealRegularDocumentList } from "../../../../../types/amr-pipeline/enums/DocumentType";
import { AmrDocument } from "../../../../../types/amr-pipeline/models/AmrDocument";
import DownloadDocument from "../../../../amrPipeline/common/documents/DownloadDocument";
import { FilesUploaderChildProps } from "../../../../amrPipeline/common/documents/FileUploader";
import { EmptySectionContent } from "../../../../amrPipeline/common/section/EmptySectionContent";
import { Table } from "../../../../bidding/common/table";
import { ColumnBuilder } from "../../../../bidding/common/table/columns/column-builder/ColumnBuilder";
import { SelectField } from "../../../../common/form-fields/SelectField";
import { DateField } from "../../../../common/form-fields/DateField";
import { CheckboxField } from "../../../../common/form-fields/CheckboxField";
import { FileIcon, ProgressCircle } from "../../../../controls";
import { DocumentColumn, DocumentColumnDate } from "../DocumentsList";
import { DocumentsForm, DocumentsFormWithPatterns } from "./EditDocuments";
import useDownloadDocumentList from "../../../../../effects/useDownloadDocumentList";
import { dealsService } from "../../../../../services/deals.service";

interface Props extends FilesUploaderChildProps {
    documentKey: string;
    withTypeColumn?: boolean;
    withPublicColumn?: boolean;
    disabledDate?: boolean;
    documentColumnDate?: DocumentColumnDate;
}

export function EditDocumentsList({
    referenceName,
    documents,
    progress,
    handleDeleteFile,
    documentKey,
    withTypeColumn,
    withPublicColumn = false,
    disabledDate = false,
    documentColumnDate = DocumentColumnDate.executionDate,
}: Props) {
    const { values: {monthlyReportsPattern, ...docs} } = useFormikContext<DocumentsFormWithPatterns>();
    const currentList = docs[documentKey as keyof DocumentsForm];

    const { loadingState, loadHandler } = useDownloadDocumentList({
        documents,
        downloadRequest: (documentReferenceName: string) => dealsService.getDealDocument(referenceName || '', documentReferenceName),
    });

    const getFormikIndex = (documentReferenceName: string) =>
        currentList.findIndex((l) => l.referenceName === documentReferenceName);

    const downloadDocument = (documentToDownload: AmrDocument) => {
        if (referenceName) {
            loadHandler(documentToDownload.referenceName, documentToDownload.name);
        }
    };

    const getTableColumns = () => {
        const documentsColumnDefinitions: DocumentColumn[] = [
            {
                columnKey: "name",
                renderColumnHeaderContent: () => "Name",
                headerClassName: "data-list-cell-xl-flexible",
                bodyClassName: "data-list-cell-xl-flexible",
                renderColumnContent: (document) => (
                    <DownloadDocument
                        document={document}
                        onClick={() => downloadDocument(document)}
                        disabled={!document.referenceName}
                    >
                        <FileIcon filename={document.name} />
                        {document.name}
                    </DownloadDocument>
                ),
                sortingField: "name",
                sortingType: SORTING_TYPE.string,
            },
            {
                columnKey: "progress",
                renderColumnContent: (document) =>
                    document.referenceName ? null : (
                        <ProgressCircle progress={progress[document.name]} />
                    ),
                renderColumnHeaderContent: () => "",
                headerClassName: "data-list-cell-xxs",
                bodyClassName: "data-list-cell-xxs",
            },
            ...(withTypeColumn
                ? [
                    {
                        columnKey: "documentType",
                        renderColumnHeaderContent: () => "Type",
                        headerClassName: "data-list-cell-lg-02",
                        bodyClassName: "data-list-cell-lg-02",
                        renderColumnContent: (document) => (
                            <SelectField
                                name={`${documentKey}.${getFormikIndex(
                                    document.referenceName
                                )}.documentType`}
                                values={dealRegularDocumentList}
                                placeholder="Select Document Type"
                                optionLabelProp="text"
                                optionKeyProp="value"
                                className="form-control-sm"
                                disabled={!document.referenceName}
                            />
                        ),
                    } as DocumentColumn,
                ]
                : []),
            {
                columnKey: documentColumnDate === DocumentColumnDate.executionDate ? 'executionDate' : 'uploadTime',
                renderColumnHeaderContent: () => 'Date',
                headerClassName: 'data-list-cell-lg',
                bodyClassName: 'data-list-cell-lg',
                renderColumnContent: document => {
                    const documentIndex = `${documentKey}.${getFormikIndex(document.referenceName)}`;

                    const documentName =
                        documentColumnDate === DocumentColumnDate.executionDate
                            ? `${documentIndex}.executionDate`
                            : `${documentIndex}.uploadTime`;

                    return (
                        <DateField
                            withCustomView
                            disabled={disabledDate || !document.referenceName}
                            dateFormat={constants.dateFormat}
                            maximumDate={new Date()}
                            name={documentName}
                        />
                    );
                },
            },
            ...(withPublicColumn
                ? [
                    {
                        columnKey: 'isPublic',
                        renderColumnHeaderContent: () => 'Public',
                        headerClassName: 'data-list-cell-xxs-02 text-center',
                        bodyClassName: 'data-list-cell-xxs-02 text-center',
                        renderColumnContent: document => (
                            <CheckboxField
                                name={`${documentKey}.${getFormikIndex(document.referenceName)}.isPublic`}
                                disabled={!document.referenceName}
                            />
                        ),
                    } as DocumentColumn,
                ]
                : []),
            {
                columnKey: "downloadButton",
                renderColumnHeaderContent: () => "",
                headerClassName: "data-list-cell-xxs-02 text-right",
                bodyClassName: "data-list-cell-xxs-02 text-right",
                renderColumnContent: (document, { isLoading }) => (
                    <DownloadDocument
                        document={document}
                        onClick={() => downloadDocument(document)}
                        disabled={!document.referenceName}
                        isLoading={isLoading}
                    >
                        <IconSVG
                            name="downloadTemplate"
                            width={16}
                            height={16}
                        />
                    </DownloadDocument>
                ),
            },
            {
                columnKey: "delete-action",
                renderColumnContent: ({ referenceName }) => (
                    <button
                        className={classNames("btn-link btn-danger", { disabled: !referenceName })}
                        onClick={referenceName ? handleDeleteFile(referenceName) : undefined}
                    >
                        <IconSVG name="basket" width={16} height={16} />
                    </button>
                ),
                renderColumnHeaderContent: () => "",
                headerClassName: "data-list-cell-xxs padding-l-0",
                bodyClassName: "data-list-cell-xxs padding-l-0",
            },
        ];

        return documentsColumnDefinitions.map((c) => {
            return new ColumnBuilder(c);
        });
    };

    return (
        <div className="component-file-upload-list">
            {documents.length ? (
                <Table
                    columns={getTableColumns()}
                    dataItems={documents}
                    className="component-file-upload-list data-list-striped"
                    createSecurityCustomArgs={(item: AmrDocument) => ({
                        isLoading: loadingState[item.referenceName],
                    })}
                    createRowCustomKey={(
                        document: AmrDocument,
                        index: number,
                    ) => document.referenceName || index}
                />
            ) : (
                <EmptySectionContent text="There are no documents yet" />
            )}
        </div>
    );
}

